<template>
  <div class="animated fadeIn">
    <table-custom
      ref="dataTable"
      :name="`${$customTable.getCustomTableName($route.meta.module)}`"
      :loading="dataTable.isLoading"
      :data="dataTable.dataSet"
      :options="dataTable.options"
      @view-item="viewItem"
      @edit-item="editItem"
      @delete-item="deleteItem"
    >
      <div slot="afterFilter">
        <b-row>
          <b-col>
            <b-button-group>
              <b-button variant="outline-dark" title="Add Product" @click="addItem()">
                <font-awesome-icon icon="plus" /> Create
              </b-button>
            </b-button-group>
          </b-col>
        </b-row>
      </div>
    </table-custom>
  </div>
</template>

<script>
import TableCustom from '@/components/TableCustom'

export default {
  props: {
    invoiceId: {
      type: String,
      default: ''
    }
  },
  name: 'ProductsTable',
  components: {
    TableCustom
  },
  data: function () {
    return {
      rawData: {},
      dataTable: {
        //loading: false,
        isLoading: false,

        dataSet: [],
        options: {
          columns: [
            'ID',
            'Notes',

            'Product Name Full',
            'Category',
            'Base/Finished Product',
            'Bottle Size',
            'ABV',
            'Product Type',
            'Bottle Height',
            'Bottle Width',
            'Bottle Weight',
            'Case Size',
            'Cases per Pallet',
            'Layers per Pallet',
            'Case Dimensions',
            'Case Height',
            'Case Weight',
            'Case Price',
            'Bottle Price',
            'GS1 UPC',
            'SCC Code',
            'NABCA Code',
            'TTB ID',
            'Schedule B',
            'CN Codes EU',

            'Unit Price',

            'Discontinued'
          ],
          filterable: [
            'ID',
            'Notes',
            'Product Name',
            'Product Name Full',
            'Category',
            'Base/Finished Product',
            'Bottle Size',
            'ABV',
            'Product Type',
            'Bottle Height',
            'Bottle Width',
            'Bottle Weight',
            'Case Size',
            'Case per Pallet',
            'Layers per Pallet',
            'Case Dimensions',
            'Case Height',
            'Case Weight',
            'Case Price',
            'Bottle Price',
            'GS1 UPC',
            'SCC Code',
            'NABCA Code',
            'TTB ID',
            'Schedule B',
            'CN Codes EU',

            'Unit Price',

            'Discontinued'
          ],
          formatColumns: [
            {
              name: 'Unit Price',
              style: {
                style: 'currency',
                currency: 'USD'
              }
            },
            {
              name: 'Qty/Unit',
              style: {
                style: 'decimal'
              }
            }
          ],
          footerHeadings: false,
          perPage: 50,
          disablePerPageDropdown: false,
          showActions: true
        }
      }
    }
  },
  computed: {},
  mounted () {
    if (this.invoiceId) this.getData()
  },
  methods: {
    onFilter () {
      this.dataTable.totalRecords = this.$refs.dataTable.data.length
    },

    async getData (payload) {
      let self = this

      this.dataTable.isLoading = true

      //  this.dataTable.dataSet = [];

      let response = []

      try {
        if (this.invoiceId) {
          response = await this.$api.get(`invoices/${this.invoiceId}/products`)
        } else {
          response = await this.$api.post('products', payload)

          console.log('products', response)
        }

        self.dataTable.isLoading = false

        self.dataTable.dataSet = response

        if (response.length === 0) return

        self.$emit('loaded', self.dataTable.dataSet.length)
      } catch (error) {
        console.log(error)
        self.dataTable.isLoading = false
        self.$form.msgBoxOk('Error occured')
      }
    },
    addItem: function () {
      this.$router.push({
        name: 'Product submission',
        params: {
          action: 'create'
        }
      })
    },
    viewItem: function (id) {
      this.$router.push({
        name: 'Product submission',
        params: {
          action: 'view',
          id: id
        }
      })
    },
    editItem: function (id) {
      this.$router.push({
        name: 'Product submission',
        params: {
          action: 'edit',
          id: id
        }
      })
    },
    deleteItem: async function (id) {
      let item = this.dataTable.dataSet.find(item => item.ID === id)

      let confirm = await this.$form.showConfirmation(`Product #${item.ID} will be deleted. Do you want to proceed?`)

      if (!confirm) return

      let self = this

      this.$api
        .delete(`products/${item.ID}`)
        .then(response => {
          self.$form.makeToastInfo(response.message)

          self.getData()
        })
        .catch(response => {
          console.log(response)

          self.$form.makeToastError(response.message)
        })
    }
  },
  watch: {}
}
</script>

<style scoped>
:deep(.default-cell) {
  word-wrap: break-word !important;
}

:deep(.VueTables__table th:nth-child(3)),
:deep(.VueTables__table td:nth-child(3)) {
  min-width: 20em;
}
</style>
